<template>
  <b-container class="page-login">
    <div class="pt-2">
      <h1 class="text-deleev text-deleev-title text-center">Deleev</h1>
      <b-card border-variant="light">
        <b-form @submit.prevent="login">
          <b-form-group class="mb-2">
            <label class="small text-muted mb-0">E-mail</label>
            <b-input-group>
              <template v-slot:prepend>
                <span class="bg-light text-muted border"><i class="fas fa-at" /></span>
              </template>
              <b-form-input v-model="email" type="email" required autocomplete="off" placeholder="e-mail..." />
            </b-input-group>
          </b-form-group>
          <b-form-group class="mb-2">
            <label class="small text-muted mb-0">Mot de passe</label>
            <b-input-group>
              <template v-slot:prepend>
                <span class="bg-light text-muted border"><i class="fas fa-lock" /></span>
              </template>
              <b-form-input v-model="password" type="password" required autocomplete="off" placeholder="mot de passe..." />
            </b-input-group>
          </b-form-group>
          <b-form-group class="text-center mb-0">
            <b-button type="submit" size="sm" variant="primary">Connexion</b-button>
            <b-form-invalid-feedback :state="authStatus !== 'error'">E-mail ou mot de passe invalide,<br />re-vérifiez vos paramètres de connexion ou contactez l'administrateur.</b-form-invalid-feedback>
          </b-form-group>
        </b-form>
      </b-card>
      <div class="text-center mt-4">
        <b-button variant="danger" size="sm" :to="{ name: 'registration' }">Inscription (Test)</b-button>
      </div>
    </div>
  </b-container>
</template>

<script>
import store from '../store'
import eventEmitter from '../event-emitter'

export default {
  name: 'Login',
  created () {
    eventEmitter.on('auth:updated', this.onAuthUpdated)
  },
  beforeDestroy () {
    eventEmitter.off('auth:updated', this.onAuthUpdated)
  },
  data () {
    return {
      authStatus: '',
      email: '',
      password: ''
    }
  },
  methods: {
    onAuthUpdated () {
      if (store.isLogged()) {
        let _redirect = this.$router.currentRoute.query.redirect
        if (!_redirect || _redirect === '/') {
          _redirect = store.userCan('test') ? '/tests/qcm' : '/'
        }
        this.$router.push(_redirect)
      } else {
        this.authStatus = 'error'
      }
    },
    login () {
      this.authStatus = ''
      let email = this.email
      let password = this.password
      store.login({ email, password })
    }
  }
}
</script>

<style scoped>
.card {
  margin: 1rem auto 0 auto;
  width: 360px;
  max-width: 100%;
}
.card .input-group span {
  min-width: 30px;
  text-align: center;
  font-size: 90%;
  line-height: 28px;
  border-radius: 0.25rem 0 0 0.25rem;
}
</style>
