'use strict'

const _getAjaxErrorMessage = (response) => {
  if (typeof response === 'string') {
    return response
  }
  if (response.response) response = response.response
  if (response.data) response = response.data
  if (response instanceof Error) {
    response = { errors: response.message }
  }
  if (response.error || response.detail) {
    response = { errors: response.error || response.detail }
  }
  if (!response || !response.errors) {
    return 'No error message.'
  }

  const defaultMessage = 'Erreur inconnue.'
  if (Array.isArray(response.errors)) {
    response.errors = response.errors.join(' ')
  }
  return response.errors || defaultMessage
}

export default {
  getAjaxErrorMessage: _getAjaxErrorMessage
}
