<template>
  <footer class="d-print-none text-center mt-4 pt-2 pb-1">
    <div class="foot-menu">
      <b-link :to="{ name: 'test-pda-read-barcode' }">Test Scan</b-link>
      <span>•</span>
      <b-link :to="{ name: 'bluetooth-spp-init' }">Bluetooth mode SPP</b-link>
    </div>
    <div class="smaller text-muted mt-1">@ deleev 2022</div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
.foot-menu a, .foot-menu a:hover {
  font-size: 90%;
  color: #898989;
  text-decoration: none;
  display: inline-block;
  padding: 2px 6px;
}
.foot-menu span {
  color: #a2a2a2;
  display: inline-block;
  width: 10px;
}
</style>
