<template>
  <b-container class="page-home">
    <vue-tests-home v-if="userIsTest" class="pt-3" />
    <div v-else-if="userIsAdmin">
      <div class="pt-5 nav-menu d-flex flex-column">
        <b-button variant="success" class="mb-2 py-2 text-uppercase" :disabled="!userCanMeS" :to="{ name: 'stocks-home' }">Stocks</b-button>
        <b-button v-if="false" variant="info" class="mb-2 py-2 text-uppercase" disabled>Commandes</b-button>
        <b-button v-if="userIsRecrutement" variant="primary" class="my-2 py-2" :to="{ name: 'tests-products' }">Produits du test<div class="text-uppercase">préparateur de commande</div></b-button>
      </div>
      <div class="text-center my-5">
        <b-button variant="link" size="sm" class="text-danger text-decoration-none" @click="$emit('logout')"><i class="fas fa-sign-out-alt"></i> déconnexion</b-button>
      </div>
    </div>
  </b-container>
</template>

<script>
import store from '@/store'
import VueTestsHome from '@/components/tests/Home.vue'

export default {
  name: 'Home',
  components: {
    VueTestsHome
  },
  created () {
    window.scrollTo(0, 0)
    if (!this.userIsTest && !this.userIsRecrutement) {
      this.$router.push({ name: 'stocks-home' })
    }
  },
  data () {
    return {
      userIsAdmin: store.userCan('admin'),
      userIsRecrutement: store.userCan('recrutement'),
      userCanMeS: store.userCan('MeS'),
      userIsTest: store.userCan('test')
    }
  }
}
</script>

<style scoped>
  .nav-menu {
    width: 100%;
    max-width: 340px;
    margin: 0 auto;
  }
</style>
