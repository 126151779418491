'use strict'

const _confirmParams = {
  title: 'Confirmation',
  buttonSize: 'sm',
  okTitle: 'Continuer',
  okVariant: 'danger',
  cancelTitle: 'Annuler',
  cancelVariant: 'light',
  headerClass: 'pt-2 pb-1',
  footerClass: 'pt-0 pb-1',
  centered: true,
  autoFocusButton: 'ok'
}

const userAgent = navigator.userAgent
const _isPDA = () => userAgent.indexOf('IPDA030') !== -1 || userAgent.indexOf('SPA43LTE') !== -1 || userAgent.indexOf('EDA51') !== -1 || userAgent.indexOf('UA-Stock') !== -1
const _isAndroid = () => userAgent.indexOf('Android') !== -1
const _isIOS = () => userAgent.indexOf('iPhone') !== -1 || userAgent.indexOf('iPad') !== -1 || userAgent.indexOf('iPod') !== -1
const _isMobile = () => _isAndroid(userAgent) || _isIOS(userAgent)

const _gs1RegEx = /^01(\d{14})17(\d{6})$/
const _laboRegEx = /^99(\d{4})0(\d{6})$/
const _expireAtReplaceRegex = /([0-9]{4})([0-9]{2})([0-9]{2})/
const _isFrichtiBarcode = (barcode) => {
  // Frichti GS1/gtin (prefix 01 puis 14 digits) et expiration date (prefix 17 + 6 digits)
  let _response = {}
  if (barcode.length === 24) {
    let _match = barcode.match(_gs1RegEx)
    if (_match) {
      if (_match[1]) {
        _response.barcode = _match[1].slice(2)
      }
      if (_match[2] && _match[2].length === 6) {
        _response.expire_at = `20${_match[2]}`.replace(_expireAtReplaceRegex, '$1-$2-$3')
      }
    }
  }
  return _response.barcode ? _response : null
}

const _isLaboBarcode = (barcode) => {
  let _response = {}
  if (barcode.length === 13) {
    let _match = barcode.match(_laboRegEx)
    if (_match) {
      if (_match[2]) {
        _response.barcode = `9000000${_match[2]}`
      }
      if (_match[1]) {
        const _now = new Date()
        const _currentMonth = +_now.getMonth()
        const _expireMonth = +_match[1].slice(0, 2)
        const _diff = _expireMonth - _currentMonth
        const _year = _now.getFullYear() + (_diff <= -6 ? 1 : 0)
        _response.expire_at = `${_year}${_match[1]}`.replace(_expireAtReplaceRegex, '$1-$2-$3')
      }
    }
  }
  return _response.barcode ? _response : null
}

const _isSupplierOrderBarcode = (barcode) => {
  let _response = {}
  if (barcode.indexOf('ofid:') === 0) {
    let _parts = barcode.split(':')
    if (_parts.length === 3) {
      _response.barcode = _parts[2]
      _response.order_form_id = +_parts[1]
    }
  }
  return _response.barcode ? _response : null
}

const _resolveBarcode = (barcode) => {
  return _isSupplierOrderBarcode(barcode) || _isFrichtiBarcode(barcode) || _isLaboBarcode(barcode) || null
}

export default {
  confirmParams: () => _confirmParams,
  isPDA: _isPDA,
  isAndroid: _isAndroid,
  isIOS: _isIOS,
  isMobile: _isMobile,
  currentWhereDevice: () => {
    if (_isPDA()) return 'PDA'
    if (_isMobile()) return 'Mobile'
    return 'Desktop'
  },
  resolveBarcode: _resolveBarcode
}
