'use strict'

import mNumbers from './mixins/numbers'
import mObjects from './mixins/objects'
import mString from './mixins/string'
import mDates from './mixins/dates'
import mProducts from './mixins/products'
import mCenters from './mixins/centers'
import mSites from './mixins/sites'
import mUsers from './mixins/users'
import mUtils from './mixins/utils'

const _allMethods = [
  { prefix: 'numbers', methods: mNumbers },
  { prefix: 'objects', methods: mObjects },
  { prefix: 'string', methods: mString },
  { prefix: 'dates', methods: mDates },
  { prefix: 'products', methods: mProducts },
  { prefix: 'centers', methods: mCenters },
  { prefix: 'sites', methods: mSites },
  { prefix: 'users', methods: mUsers },
  { prefix: 'utils', methods: mUtils }
]

let _methods = {}
_allMethods.forEach(o => {
  Object.keys(o.methods).forEach((name) => {
    _methods[o.prefix + mString.capitalize(name)] = o.methods[name]
  })
})

export default {
  methods: _methods
}
