<template>
  <header class="d-print-none">
    <b-navbar variant="light" fixed="top" class="py-0 px-2">
      <router-link to="/" class="navbar-brand text-deleev"><i class="fas fa-bars mr-1"></i> Deleev</router-link>
      <b-collapse is-nav>
      </b-collapse>
      <small class="text-muted mr-1">{{user.email}}</small>
      <b-button variant="light" size="sm" class="text-secondary" @click="$emit('logout')"><i class="fas fa-sign-out-alt"></i></b-button>
    </b-navbar>
  </header>
</template>

<script>
export default {
  name: 'Header',
  props: {
    user: Object
  },
  methods: {
    isActiveRoute (route) {
      return route === this.$route.name
    }
  }
}
</script>

<style scoped>
  header nav .navbar-brand {
    font-size: 1.8em;
    text-decoration: none;
    padding: 4px 0 0px 0;
  }
</style>
