'use strict'

const _sites = [
  { id: 1, name: 'La Belle Vie' },
  { id: 8, name: 'Bam Courses' },
  { id: 9, name: 'Bam Courses - Grand choix' },
  { id: 10, name: 'La Belle Vie - Express' }
]

export default {
  getSites: () => _sites,
  getSite: (site) => _sites.find(o => o.id === site) || { id: 0, name: '?' }
}
