<template>
  <div id="appWrap" :class="{ 'hide-menu': !showMenu }">
    <VueHeader v-if="showMenu" @logout="logout" :user="user" />
    <router-view v-if="appIsReady" class="app-wrapper" @logout="logout" :style="{ 'min-height': wrapMinHeight + 'px' }" />
    <router-view v-if="appIsReady" class="app-wrapper-modal" name="modal" />
    <VueFooter />
  </div>
</template>

<script>
import store from './store'
import mUtils from './mixins/utils'
import eventEmitter from './event-emitter'
import VueHeader from '@/components/Header.vue'
import VueFooter from '@/components/Footer.vue'

const _barcodeToZone = (barcode) => {
  if (barcode.length === 14) {
    const _parts = barcode.split('-')
    if (_parts.length === 3) {
      return { area: +_parts[0], shelf: +_parts[1], tier: +_parts[2] }
    }
  }
  return false
}
const _barcodeToCrate = (barcode) => {
  if (barcode.length === 5) {
    const _parts = barcode.split('-')
    if (_parts.length === 2) {
      return { code: barcode }
    }
  }
  return false
}

window.barcodeRead = (barcode, addressMac, serialNumber) => {
  barcode = barcode.replace(/§/g, '-')

  let expireAt = null
  let orderFormId = null
  const barcodeResolved = mUtils.resolveBarcode(barcode)
  if (barcodeResolved) {
    barcode = barcodeResolved.barcode
    expireAt = barcodeResolved.expire_at || null
    orderFormId = barcodeResolved.order_form_id || null
  }

  barcode && eventEmitter.emit('barcode:scanned', {
    barcode: barcode,
    order_form_id: orderFormId,
    expire_at: expireAt,
    zone: _barcodeToZone(barcode),
    crate: _barcodeToCrate(barcode),
    mac_address: addressMac,
    serial_number: serialNumber
  })
}

export default {
  name: 'App',
  components: {
    VueHeader,
    VueFooter
  },
  watch: {
    $route (to, from) {
      this.showMenu = this.displayHeader()
    }
  },
  data () {
    return {
      wrapMinHeight: 400,
      appIsReady: false,
      showMenu: true,
      isLogged: false,
      user: {}
    }
  },
  created () {
    store.lookupCenter(() => {
      this.appIsReady = true
    })

    eventEmitter.on('auth:updated', this.initUser)
    this.initUser()
  },
  mounted () {
    this.$nextTick(() => {
      this.setWrapMinHeight()
    })
  },
  methods: {
    setWrapMinHeight () {
      this.wrapMinHeight = window.innerHeight - 115
    },
    displayHeader () {
      return this.isLogged && !(
        (this.$router.currentRoute.name === 'stocks' && this.$router.currentRoute.params.action !== 'menu') ||
        this.$router.currentRoute.name === 'tests-trial'
      )
    },
    logout () {
      store.logout()
    },
    initUser () {
      this.isLogged = store.isLogged()
      if (this.isLogged) {
        this.user = store.getUser()
      } else {
        this.user = {}
        const _isOfflineRoutes = ['registration', 'login', 'test-pda-read-barcode', 'bluetooth-spp-init'].indexOf(this.$router.currentRoute.name) !== -1
        !_isOfflineRoutes && this.$router.push('/login')
      }
      this.showMenu = this.displayHeader()
    }
  }
}
</script>
