'use strict'

import store from '@/store'

// Mettre à jour
// let output = {};
// suppliers.filter(o => !o.is_hidden && o.category !== 7).forEach(o => { output[o.id] = o.name })
import suppliers from '../data/suppliers.json'

const _stocksActions = [
  { value: 'stockpiling', text: 'Mise en stock', variant: 'success' },
  { value: 'stockpilinginternal', text: 'Mise en stock (REAPPRO uniquement)', variant: 'secondary' },
  { value: 'stockpilingwithoutbarcode', text: 'Mise en stock (sans code barre)', variant: 'warning' },
  { value: 'replace', text: 'Replacer', variant: 'info' },
  { value: 'rezone', text: 'Rezoner', variant: 'danger' },
  { value: 'inventory', text: 'Inventaire', variant: 'primary' },
  { value: 'inventory-bulk', text: 'Inventaire en masse', variant: 'orange' },
  { value: 'inventory-zone', text: 'Actions par zone', variant: 'dark' },
  { value: 'replace-bag', text: 'Re-stock par n° de commande', variant: 'secondary' },
  { value: 'antigaspi', text: 'Packs anti-gaspi', variant: 'warning' },
  { value: 'areaclassification', text: 'Classification des zones', variant: 'blue' },
  { value: 'productclassification', text: 'Classification des produits', variant: 'beaver' },
  { value: 'zones-classification', text: 'Liste des classifications - zones', variant: 'seagreen' },
  { value: 'cratecolor', text: 'Caisses - couleurs', variant: 'purple' },
  { value: 'product-update', text: 'Infos produit', variant: 'rose' },
  { value: 'inventory-dlc', text: 'Inventaire DLC courte', variant: 'dark' },
  { value: 'product-logs', text: 'Historique des logs produit', variant: 'outline-dark' }
]

const _primeurCalibreList = [
  { value: 1, text: 'très petit calibre' },
  { value: 2, text: 'petit calibre' },
  { value: 3, text: 'calibre moyen' },
  { value: 4, text: 'gros calibre' },
  { value: 5, text: 'très gros calibre' }
]

const _productUnits = [
  { value: 'unit', text: 'pièces' },
  { value: 'kilo', text: 'g' }
]

const _orderStatus = [
  { value: 'paid', text: 'Payée' },
  { value: 'prep', text: 'En prép.' },
  { value: 'prepared', text: 'Finalisée' },
  { value: 'shipping', text: 'En livraison' },
  { value: 'received', text: 'Livrée' },
  { value: 'undeliveredclient', text: 'pas-là' },
  { value: 'now', text: 'Prép. mnt.' },
  { value: 'later', text: 'Prép. + tard' },
  { value: 'important', text: 'Important' },
  { value: 'to-check', text: 'À vérif.' },
  { value: 'waiting-products', text: 'Attente de prod.' },
  { value: 'xd-to-check', text: 'Crossdocking à vérif.' },
  { value: 'stand-by', text: 'Stand by' },
  { value: 'redestock', text: 'Déstock (re)' },
  { value: 'canceled', text: 'Annulée' },
  { value: 'restock', text: 'Restock' },
  { value: 'blocked', text: 'Bloquée' }
]

const _supplierOrderStatus = [
  { value: 'aside', text: 'Mise de côté', variant: 'warning' },
  { value: 'draft', text: 'Brouillon', variant: 'light' },
  { value: 'sent', text: 'Envoyée', variant: 'info' },
  { value: 'received', text: 'Reçue', variant: 'success' },
  { value: 'blocked', text: 'Bloquée', variant: 'danger' },
  { value: 'done', text: 'Terminée', variant: 'success' },
  { value: 'canceled', text: 'Annulée', variant: 'danger' },
  { value: 'manual', text: 'Manuelle', variant: 'light' },
  { value: 'failed', text: 'Echec envoi', variant: 'danger' }
]

const _freshAreas = [7, 805].concat(Array(200).fill(0).map((v, idx) => 1000 + idx))
const _primeurAreas = [6, 12, 700, 800]
const _frozenAreas = [8]

const _getSupplierName = (supplierId) => {
  return suppliers[supplierId] || supplierId
}

const _getStockAction = (action) => _stocksActions.find(o => o.value === action) || { value: action, text: '?', variant: 'light' }

const _getPrimeurCalibre = (calibreId) => _primeurCalibreList.find(o => o.value === calibreId) || null

const _getProductUnit = (unit) => _productUnits.find(o => o.value === unit) || { value: unit, text: unit }

const _getOrderStatus = (status) => _orderStatus.find(o => o.value === status) || { value: status, text: status }

const _getSupplierOrderStatus = (status) => _supplierOrderStatus.find(o => o.value === status) || { value: status, text: status }

const _getZone = (data) => ({ area: +data.area, shelf: +data.shelf, tier: +data.tier })
const _formatZone = (zone) => [zone.area, zone.shelf, zone.tier].join('.')
const _isSameZone = (source, zone) => {
  return +source.area === +zone.area && +source.shelf === +zone.shelf && +source.tier === +zone.tier
}
const _isValidZone = (zone) => {
  if (zone.area === null || zone.shelf === null || zone.tier === null) return false
  if (typeof zone.area === 'undefined' || typeof zone.shelf === 'undefined' || typeof zone.tier === 'undefined') return false
  if (!(zone.area + '').length || !(zone.shelf + '').length || !(zone.tier + '').length) return false
  if (+zone.area === 0) return false
  if (_formatZone(zone) === '0.0.0') return false
  return true
}

const _isBannedZoneForMeS = (zone) => {
  let _zoneClassification = store.getZoneClassifications(_formatZone(zone)).map(id => store.getAreaClassificationById(id).name)
  return _zoneClassification.indexOf('X') !== -1
}

const _isFreshZones = (area) => {
  return _freshAreas.indexOf(area) !== -1
}
const _isPrimeurZones = (area) => {
  return _primeurAreas.indexOf(area) !== -1
}
const _isFrozenZones = (area) => {
  return _frozenAreas.indexOf(area) !== -1
}
const _isSecZones = (area) => {
  return area >= 10 && !_isFreshZones(area) && !_isPrimeurZones(area) && !_isFrozenZones(area)
}

const _getUVCPrice = (product) => {
  if (product.selling_method === 'weight' && product.pack > 1) {
    return (product.gross_selling_price * product.pack) / 1000
  }
  return product.gross_selling_price
}

const _getSellingNameExt = (product) => {
  let _name = product.selling_name
  if (product.primeur_calibre) _name += ` (${_getPrimeurCalibre(product.primeur_calibre).text})`
  if (product.primeur_origin) _name += `, ${product.primeur_origin}`
  return _name
}

const _getQuantityColisLabel = (quantityIdeal, supplierReference) => {
  let _packaging = supplierReference.packaging
  if (supplierReference.is_unpack && supplierReference.supplier_pack) {
    _packaging = Math.ceil(_packaging / supplierReference.supplier_pack)
  }
  let _orderQuantity = 1
  if (quantityIdeal > _packaging) {
    _orderQuantity = Math.ceil(quantityIdeal / _packaging)
  }
  return {
    order_quantity: _orderQuantity,
    denomination: `${_orderQuantity} colis de ${_packaging} pièce${_packaging > 1 ? 's' : ''}`
  }
}

export default {
  stocksActions: () => _stocksActions,
  getStocksAction: _getStockAction,
  primeurCalibres: () => _primeurCalibreList,
  getPrimeurCalibre: _getPrimeurCalibre,
  getProductUnit: _getProductUnit,
  getOrderStatus: _getOrderStatus,
  getSupplierOrderStatus: _getSupplierOrderStatus,
  getZone: _getZone,
  isSameZone: _isSameZone,
  isValidZone: _isValidZone,
  isBannedZoneForMeS: _isBannedZoneForMeS,
  isSecZones: _isSecZones,
  isFreshZones: _isFreshZones,
  isPrimeurZones: _isPrimeurZones,
  isFrozenZones: _isFrozenZones,
  formatZone: _formatZone,
  getUVCPrice: _getUVCPrice,
  getSellingNameExt: _getSellingNameExt,
  getSupplierName: _getSupplierName,
  getQuantityColisLabel: _getQuantityColisLabel,
  getClassificationById: (id) => store.getAreaClassificationById(id),
  getClassificationByName: (name) => store.getAreaClassificationByName(name)
}
