'use strict'

const _centers = [
  // { id: 3, name: 'Montreuil', is_free_zoning_enabled: false },
  { id: 9, name: 'Reuilly', is_free_zoning_enabled: true }
]

export default {
  getCenters: () => _centers,
  getCenter: (center) => _centers.find(o => o.id === center) || { id: 0, name: '?' }
}
