<template>
  <b-container class="page-notfound">
    <vue-not-found :title="title" :message="message" />
  </b-container>
</template>

<script>
import VueNotFound from '../components/helpers/notFound.vue'

export default {
  name: 'NotAuthorized',
  components: {
    VueNotFound
  },
  data () {
    return {
      title: 'Oups page à accès restreint',
      message: 'Vous n\'avez pas accès à la page que vous avez demandé'
    }
  }
}
</script>
