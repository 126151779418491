<template>
  <b-container class="page-notfound">
    <vue-not-found :title="title" :message="message" />
  </b-container>
</template>

<script>
import VueNotFound from '../components/helpers/notFound.vue'

export default {
  name: 'NotFound',
  components: {
    VueNotFound
  },
  data () {
    return {
      title: 'Oups page introuvable',
      message: 'La page que vous avez demandé n\'existe pas'
    }
  }
}
</script>
