var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('p',[_vm._v("Quand vous trouvez le produit, il faut scanner le code barre. Le produit suivant apparaitra alors. Il est important de reposer le produit à l’endroit exacte ou vous l’avez pris.")]),_c('p',[_vm._v("Faites attention à bien scanner le code barre du produit et non le code QR s'il est présent sur le produit.")]),_vm._m(6),_c('p',[_vm._v("Attention, dans certains cas l’un des produits peut se trouver sur les étagères environnantes de celle indiquée.")]),_vm._m(7),_vm._m(8),_c('p',[_vm._v("Si vous êtes déconnecté durant le test, vous devez vous reconnecter avec les mêmes identifiants de l'inscription. Vous reprendrez alors le test où vous vous étiez arrêté.")]),_c('p',[_vm._v("Durant ce test vous avancerez petit à petit dans la zone, vous ne devez jamais revenir en arrière.")]),_c('p',[_vm._v("A vous de jouer.")]),_c('p',[_vm._v("Rendez vous à la zone de départ et attendez le feu vert du responsable avant de lancer le test.")]),_c('p',[_vm._v("Bon courage")]),_c('br'),_c('div',{staticClass:"border rounded bg-light text-center mt-3 mb-5 py-2"},[_vm._m(9),_c('div',{staticClass:"mt-2 smallx"},[(_vm.barcode)?_c('span',[_c('span',[_vm._v("Code barre : ")]),_c('strong',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.barcode))])]):_c('span',{staticClass:"smallx font-italic"},[_c('b-spinner',{attrs:{"small":"","type":"grow"}}),_c('span',{staticClass:"ml-2"},[_vm._v("En attente de la lecture du code barre...")])],1)])]),_c('div',{staticClass:"nav-menu d-flex flex-column text-center mt-3 mb-2"},[_c('div',{staticClass:"text-muted small"},[_vm._v("Attendez le feu vert du responsable pour commencer")]),_c('b-button',{staticClass:"py-2 text-uppercase",attrs:{"variant":"danger","to":{ name: 'tests-trial' }}},[_vm._v("Commencer le test")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Bienvenue dans la session de test "),_c('strong',[_vm._v("La Belle Vie")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Vous allez devoir scanner "),_c('strong',[_vm._v("un maximum de produits en un minimum de temps")]),_vm._v(", sans faire d’erreurs. Le but est de scanner le "),_c('strong',[_vm._v("code barre")]),_vm._v(" du produit. Certains produits se trouvent dans des cartons. Il ne faut pas scanner le code barre du carton mais celui du produit.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center mb-3"},[_c('div',{staticClass:"w-50 ml-4 mr-1"},[_c('img',{staticClass:"w-100 rounded",attrs:{"src":"/images/figure-1.png"}})]),_c('div',{staticClass:"w-50 ml-1 mr-4"},[_c('img',{staticClass:"w-100 rounded",attrs:{"src":"/images/figure-2.png"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mb-0"},[_vm._v("Chaque produit possède un emplacement en trois parties, par exemple : "),_c('strong',[_vm._v("101.3.5")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-3 mb-3"},[_c('div',[_vm._v("- Le premier nombre "),_c('strong',[_vm._v("101")]),_vm._v(" est le numéro de l’"),_c('strong',[_vm._v("allée")])]),_c('div',[_vm._v("- Le deuxième "),_c('strong',[_vm._v("3")]),_vm._v(" est le numéro de l’"),_c('strong',[_vm._v("étagère")])]),_c('div',[_vm._v("- Le troisième "),_c('strong',[_vm._v("5")]),_vm._v(" est le numéro de l’"),_c('strong',[_vm._v("étage")]),_vm._v(" sur l’étagère")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("La zone du test est configurée "),_vm._v(" de manière à ne jamais revenir en arrière. Les numéros des allées augmentent donc au fur et à mesure que vous progressez.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-3 mb-3"},[_c('img',{staticClass:"w-100 rounded",attrs:{"src":"/images/figure-3.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("En cas de produit "),_c('strong',[_vm._v("absent")]),_vm._v(", il faudra cliquer sur les bouton approprié.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mb-4"},[_c('span',{staticClass:"bg-info text-white rounded p-2"},[_c('span',{staticClass:"text-uppercase"},[_vm._v("Absent")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-muted smallx"},[_vm._v("Tester mon PDA avant de commencer le test."),_c('br'),_vm._v("Scannez un code barre")])
}]

export { render, staticRenderFns }