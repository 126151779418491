'use strict'

const DELEEV_ADMIN_URL = process.env.VUE_APP_DELEEV_ADMIN_URL

const _gerPrepAdminLink = (user, authToken) => {
  return `${DELEEV_ADMIN_URL}/check_auth/?user_id=${user.id}&auth_token=${authToken}&next=/prep/`
}

export default {
  gerPrepAdminLink: _gerPrepAdminLink
}
