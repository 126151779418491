'use strict'

import Vue from 'vue'
import VueCookies from 'vue-cookies'
import eventEmitter from './event-emitter'
import { ApiDeleevConfig } from './api/index'
import ApiDeleevCenters from './api/deleev/centers'
import ApiDeleevUsers from './api/deleev/users'
import ApiDeleevProducts from './api/deleev/products'

Vue.use(VueCookies)
Vue.$cookies.config('30d', '/', process.env.NODE_ENV === 'production' ? '.deleev.com' : '')

// 127153 - COVILLE Laure - laure.coville@deleev.com
// 163671 - NINEL Dominique - dominique.ninel@deleev.com
// 136892 - REBAUD Julien - julien.rebaud@deleev.com
// 105606 - KOUZA Samy - samy.kouza@deleev.com
// 50519 - BANSIMBA Genipha - genipha.bansimba@deleev.com
const _superAdmin = [127153, 163671, 136892, 105606, 50519]

export default {
  state: {
    authStatus: '',
    token: '',
    user: {},
    lookupCenter: {},
    area_classifications: null,
    zones_area_classifications: null,
    serial_number: null,
    mac_address: null
  },
  async lookupCenter (cb) {
    cb = cb || (() => {})
    try {
      const centerInfo = await ApiDeleevCenters.lookup()
      if (typeof centerInfo === 'object' && centerInfo.id) {
        this.state.lookupCenter = centerInfo
      }
    } catch (e) {}
    cb()
  },
  cleanConflictCookies () {
    ['api_token', 'deleevsession'].forEach(keyName => {
      if (Vue.$cookies.isKey(keyName)) {
        Vue.$cookies.remove(keyName)
      }
    })
  },
  getStoredToken () {
    this.cleanConflictCookies()

    let _token = Vue.$cookies.get('prep_api_token')
    if (_token && _token !== 'undefined') return _token

    return ''
  },
  saveToken (token, expiresInDays) {
    Vue.$cookies.set('prep_api_token', token, expiresInDays > 0 ? (expiresInDays + 'd') : 0)
  },
  destroyToken () {
    this.state.token = ''
    this.state.user = {}
    this.saveToken('', 0)
    ApiDeleevConfig.removeAuthorizationToken()
  },
  async checkToken () {
    const token = this.getStoredToken()
    let _status = 'inprogress'
    try {
      if (!token) {
        _status = 'error'
      } else {
        const data = await ApiDeleevUsers.checkToken(token)
        if (data && data.is_staff) {
          this.setUser(data, token)
          _status = ''
        } else {
          _status = 'error'
        }
      }
    } catch (e) {
      _status = 'error'
    }
    this.state.authStatus = _status
    eventEmitter.emit('auth:ckecked')
  },
  async login (user) {
    let _status = 'inprogress'
    this.state.authStatus = _status
    try {
      const loginData = await ApiDeleevUsers.login(user)
      if (loginData && loginData.auth_token) {
        const userData = await ApiDeleevUsers.checkToken(loginData.auth_token)
        if (userData && userData.is_staff) {
          this.setUser(userData, loginData.auth_token)
          this.saveToken(loginData.auth_token, 30)
          _status = ''
        }
      } else {
        _status = 'error'
      }
    } catch (e) {
      _status = 'error'
    }
    this.state.authStatus = _status
    eventEmitter.emit('auth:updated')
  },
  async logout () {
    await ApiDeleevUsers.logout()
    this.destroyToken()
    window.location.href = '/login'
  },
  isLogged () {
    return !!this.state.token && this.state.user.is_staff
  },
  setUser (user, token) {
    this.state.token = token
    this.state.user = user

    ApiDeleevConfig.setAuthorizationToken(token)
  },
  getUser () {
    return this.state.user
  },
  getUserCurrentCenter () {
    return this.state.user && this.state.user.staff_info.current_center ? this.state.user.staff_info.current_center : null
  },
  getAccessToken () {
    return this.state.token
  },
  getLookupCenter () {
    return this.state.lookupCenter
  },
  userIsSuperAdmin () {
    const userGroup = this.state.user.groups || {}
    return userGroup.is_super_admin || (userGroup.is_acl_manager && _superAdmin.indexOf(this.state.user.id) !== -1)
  },
  userIsAdmin () {
    const userGroup = this.state.user.groups || {}
    return userGroup.is_super_admin || userGroup.is_acl_manager
  },
  userIsPrep () {
    const userGroup = this.state.user.groups || {}
    return userGroup.is_prep
  },
  userCan (permission) {
    const _user = this.getUser()
    const _userIsSuperAdmin = this.userIsSuperAdmin()
    const _userIsAdmin = this.userIsAdmin()
    const _userIsPrep = this.userIsPrep()
    switch (permission) {
      case 'super-admin':
        return _userIsSuperAdmin
      case 'admin':
        return _userIsAdmin
      case 'rezonage':
        if (_userIsSuperAdmin || _userIsAdmin) return true
        // 621  rezonage
        return _userIsPrep && _user.user_permissions.indexOf(621) !== -1
      case 'inventaire':
        if (_userIsSuperAdmin || _userIsAdmin) return true
        // 1094 inventaire
        return _userIsPrep && _user.user_permissions.indexOf(1094) !== -1
      case 'MeS':
        if (_userIsSuperAdmin || _userIsAdmin) return true
        // 1089 MeS
        return _userIsPrep && _user.user_permissions.indexOf(1089) !== -1
      case 'Prep. transfert':
        if (_userIsSuperAdmin || _userIsAdmin) return true
        // 1550 Prép. transfert
        return _userIsPrep && _user.user_permissions.indexOf(1550) !== -1
      case 'MeS RF':
        if (_userIsSuperAdmin || _userIsAdmin) return true
        // 1504 MeS RF
        return _userIsPrep && _user.user_permissions.indexOf(1504) !== -1
      case 'XpStock':
        if (_userIsSuperAdmin || _userIsAdmin) return true
        // 626 XpStock
        return _userIsPrep && _user.user_permissions.indexOf(626) !== -1
      case 'DLC':
        if (_userIsSuperAdmin || _userIsAdmin) return true
        // 1101 dlc tour - 1102 dlc sensible - 1103 dlc cuisine
        return _userIsPrep && _user.user_permissions.filter(pId => [1101, 1102, 1103].indexOf(pId) !== -1).length > 0
      case 'pack anti gaspi':
        if (_userIsSuperAdmin || _userIsAdmin) return true
        // 1105 pack anti gaspi
        return _userIsPrep && _user.user_permissions.filter(pId => [1105].indexOf(pId) !== -1).length > 0
      case 'recrutement':
        if (_userIsSuperAdmin) return true
        // Recrutement : staff_type=13
        return _user.staff_info && _user.staff_info.staff_type === 13
      case 'test':
        // 1100 tests prep
        return _user.staff_info && _user.staff_info.staff_type === 4 && _user.user_permissions.indexOf(1100) !== -1
      default:
        return false
    }
  },
  async initAreaClassifications () {
    try {
      if (!this.state.area_classifications) {
        const _areaClassifications = await ApiDeleevProducts.getAreaClassifications({ limit: 50 })
        _areaClassifications.results.sort((a, b) => a.name.localeCompare(b.name))
        this.state.area_classifications = _areaClassifications.results
      }
    } catch (e) {}
  },
  getAreaClassifications () {
    return this.state.area_classifications
  },
  getAreaClassificationById (id) {
    return this.state.area_classifications.find(o => o.id === id) || { id: id, name: id }
  },
  getAreaClassificationByName (name) {
    return this.state.area_classifications.find(o => o.name === name.toUpperCase()) || { id: name, name: name }
  },
  async initZonesAreaClassifications (centerId) {
    try {
      if (!this.state.zones_area_classifications) {
        const _zonesAreaClassifications = await ApiDeleevProducts.getAreaClassificationsZones(centerId)
        this.state.zones_area_classifications = _zonesAreaClassifications.areas
      }
    } catch (e) {}
  },
  getZonesAreaClassifications () {
    return this.state.zones_area_classifications
  },
  getZoneClassifications (zone) {
    return this.state.zones_area_classifications[zone] || []
  },
  isZoneClassificationsAllowed (zone, classifications, currentCenter) {
    return true

    /*
    const _zoneClassifications = this.getZoneClassifications(zone)
    if (!_zoneClassifications.length) {
      return true
    }
    if (_zoneClassifications.filter(x => x === 1 || x === 2).length > 0 && classifications.filter(x => x === 1 || x === 2).length > 0) {
      return true
    }

    return _zoneClassifications.filter(x => classifications.includes(x)).length > 0
    */
  },
  resetZonesAreaClassifications () {
    this.state.zones_area_classifications = null
  },
  getSerialNumber () {
    return this.state.serial_number || ''
  },
  setSerialNumber (serialNumber) {
    this.state.serial_number = serialNumber
  },
  getMacAddress () {
    return this.state.mac_address || ''
  },
  setMacAddress (macAddress) {
    this.state.mac_address = macAddress
  }
}
