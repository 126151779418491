'use strict'

import { ApiDeleev, ApiUtils, ApiDeleevConfig } from '../index'

export default {
  login (user) {
    return ApiUtils.promisePost(ApiDeleev, '/auth/login/', user)
  },
  checkToken (token) {
    return ApiUtils.promiseGet(ApiDeleev, '/admin-user/', { headers: { Authorization: 'Token ' + token } })
  },
  logout () {
    return new Promise((resolve, reject) => {
      // do api logout action
      resolve()
    })
  },
  updateUser (id, data, options) {
    return ApiUtils.promisePatch(ApiDeleev, `/users/${id}/`, data, options || {})
  },
  updateUserPerm (id, data, options) {
    return ApiUtils.promisePost(ApiDeleev, `/users/${id}/grant/`, data, options || {})
  },
  register (data) {
    return ApiUtils.promisePost(ApiDeleev, '/auth/register/', data)
  },
  registerTestUser (data) {
    data.password = data.password || 'DeleevStaff94200'
    return this.register(data)
  },
  async updateTestUser (user) {
    let updateData = {
      first_name: user.first_name,
      last_name: user.last_name,
      phone_number: user.phone_number,
      is_staff: true,
      staff_type: 3,
      staff_info: {
        user_id: user.id,
        favourite_center: 9,
        legal_status: 'freelance',
        team_enabled_at: '2020-08-06',
        is_planning_restricted: true,
        exclude_from_stat: false,
        staff_type: 4
      }
    }
    const updateResponse = await this.updateUser(user.id, updateData, { headers: ApiDeleevConfig.getHeadersWithByPassToken() })
    if (updateResponse && updateResponse.grants) {
      let perm = updateResponse.grants.find(o => o.type === 'group' && o.name === 'prep')
      if (perm) {
        perm.value = true
        await this.updateUserPerm(user.id, perm, { headers: ApiDeleevConfig.getHeadersWithByPassToken() })
      }
      perm = updateResponse.grants.find(o => o.type === 'perm' && o.name === 'tests prep')
      if (perm) {
        perm.value = true
        await this.updateUserPerm(user.id, perm, { headers: ApiDeleevConfig.getHeadersWithByPassToken() })
      }
    }
  }
}
