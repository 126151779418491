'use strict'

const _capitalize = str => {
  if (typeof str !== 'string') return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}
var _capitalizeFirsts = function (str) {
  return str.toLowerCase().replace('\t', ' ').replace(/(^| )(\w)/g, x => x.toUpperCase())
}

const _isNumber = (str) => {
  return /^\d+$/.test(str)
}

const _pad = (n, len) => {
  len = len || 2
  return ('000000000000'.substring(0, len) + n).substring(('' + n).length)
}

const _escapeHtml = (unsafe) => {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
}

const _escapeRegex = (unsafe) => {
  return unsafe.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
}

const _highlight = (str, highlight, tag) => {
  if (!highlight) return str
  tag = tag || 'mark'
  return str.replace(
    new RegExp(`(${_escapeRegex(highlight)})`, 'ig'),
    (match, p1) => `<${tag}>${_escapeHtml(p1)}</${tag}>`)
}

export default {
  capitalize: _capitalize,
  capitalizeFirsts: _capitalizeFirsts,
  pad: _pad,
  highlight: _highlight,
  isNumber: _isNumber
}
