'use strict'

import { ApiDeleev, ApiUtils } from '../index'

export default {
  lookup () {
    return ApiUtils.promiseGet(ApiDeleev, '/centers/lookup/', {}).then(function (response) {
      if (typeof response === 'object') return response
      throw new Error(response)
    })
  }
}
