'use strict'

const _date = (date, sliceAt, startAt) => {
  if (!date) return ''
  date = typeof date === 'object' ? date : new Date(date)
  return [date.toLocaleDateString(), date.toLocaleTimeString()].join(' à ').slice(startAt || 0, sliceAt || 10)
}

const _dbDate = (date) => {
  date = typeof date === 'object' ? date : new Date(date)
  date.setHours(12, 0, 0)
  return date.toISOString().slice(0, 10)
}

const MINUTE = 60
const HOUR = MINUTE * 60
const DAY = HOUR * 24
const _formatFuzzyTime = (date) => {
  let _date = date ? new Date(date.getTime()) : new Date()
  let _delta = Math.round((+(new Date()) - _date) / 1000)

  if (_delta < 5) return 'maintenant'
  if (_delta < MINUTE) return `il y a ${_delta} s.`
  if (_delta < (MINUTE + 5)) return 'il y a une minute'
  if (_delta < 5 * MINUTE) return `il y a ${Math.floor(_delta / MINUTE)}mn. et ${Math.floor(_delta % MINUTE)} s.`
  if (_delta < HOUR) return `il y a ${Math.floor(_delta / MINUTE)} mn.`
  if (Math.floor(_delta / HOUR) === 1) return 'il y a une heure'
  if (_delta < DAY) return `il y a ${Math.floor(_delta / HOUR)} h.`
  if (_delta < DAY * 2) return 'hier'
  return `il y a ${Math.floor(_delta / DAY)} j.`
}
const _formatDurationTime = (seconds, format) => {
  if (seconds < MINUTE) return `${seconds} s.`
  if (format === 'M' || seconds < HOUR) return `${Math.floor(seconds / MINUTE)} mn. et ${Math.floor(seconds % MINUTE)} s.`
  if (format === 'H' || seconds < DAY) return `${Math.floor(seconds / HOUR)} h. et ${Math.floor((seconds % HOUR) / MINUTE)} mn.`
  return `${Math.floor(seconds / DAY)} j.`
}

export default {
  date: _date,
  dbDate: _dbDate,
  formatFuzzyTime: _formatFuzzyTime,
  formatDurationTime: _formatDurationTime
}
