<template>
  <b-container class="page-bluetooth-spp-init">
    <h3 class="text-center mt-4 py-2 bg-light rounded ">Initialisation du mode<br />Bluetooth SPP</h3>
    <div>
      <b-button variant="info" size="sm" class="py-0" :to="{ name: 'home' }">
        <i class="fas fa-angle-left" /><span class="smallx ml-2">Retour au menu</span>
      </b-button>
    </div>
    <div class="text-center mt-3 pb-2 border-bottom">
      <b-form-radio-group
        button-variant="outline-danger" size="sm" buttons
        name="model" v-model="selected_model" :options="modelsoptions" />
    </div>
    <div :key="model.key" v-for="model in models_infos" class="pt-4" :class="{ 'd-none': selected_model !== model.key }">
      <h3>{{model.title}}</h3>
      <div class="thumb">
        <div>Ré-initialisation des paramètres</div>
        <img :src="model.qrc_init" />
      </div>
      <div class="thumb">
        <div>Activation du mode Bluetouth SPP</div>
        <img :src="model.qrc_spp" />
      </div>
      <div class="pdf">
        <b-button variant="danger" size="sm" :href="model.pdf" target="_blank">Voir le manuel</b-button>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'BluetoothSppInit',
  computed: {
    modelsoptions () {
      return this.models_infos.map(o => ({ value: o.key, text: o.name }))
    }
  },
  data () {
    return {
      selected_model: 'ey-017',
      models_infos: [
        {
          key: 'ey-016',
          title: 'Eyoyo EY-016',
          name: 'EY-016 - bague',
          qrc_init: '/images/EY-016-reset-configuration-to-default.png',
          qrc_spp: '/images/EY-016-bluetooth-SPP-mode.png',
          pdf: '/pdfs/EY-016.pdf'
        },
        {
          key: 'ey-017',
          title: 'Eyoyo EY-017',
          name: 'EY-017 - clip',
          qrc_init: '/images/EY-017-reset-configuration-to-default.png',
          qrc_spp: '/images/EY-017-bluetooth-SPP-mode.png',
          pdf: '/pdfs/EY-017.pdf'
        }
      ]
    }
  }
}
</script>

<style scoped>
.thumb {
  color: #727272;
  padding-top: 10px;
  padding-bottom: 24px;
  text-align: center;
  font-size: 16px;
}
.thumb img {
  width: 300px;
  max-width: 100%;
}
.pdf {
  text-align: center;
}
.pdf .btn {
  font-size: 94%;
  padding: 2px 16px;
  text-transform: uppercase;
}
</style>
