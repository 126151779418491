'use strict'

const _number = (num, precision) => {
  precision = typeof precision === 'undefined' ? 2 : precision
  return Number((parseFloat(num) || 0).toFixed(precision)).toLocaleString('fr-FR', { minimumFractionDigits: precision })
}

const _currency = (num, precision) => {
  precision = typeof precision === 'undefined' ? 2 : precision
  return Number((parseFloat(num) || 0).toFixed(precision)).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: precision })
}

const _percent = (num, precision) => {
  return _number(num, precision) + '%'
}

const _marge = (purchasePrice, sellingPrice, vat) => {
  return (sellingPrice * 100 / (100 + vat)) - purchasePrice
}

export default {
  number: _number,
  currency: _currency,
  percent: _percent,
  marge: _marge
}
