'use strict'

import Axios from 'axios'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueApp from './App.vue'
import eventEmitter from './event-emitter'
import router from './router'
import store from './store'
import mixins from './mixins.js'

import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/styles.css'

Vue.config.productionTip = false
Vue.prototype.$http = Axios
Vue.mixin(mixins)
Vue.use(BootstrapVue)
Vue.use(Vuelidate)

let _app = null
eventEmitter.on('auth:ckecked', () => {
  _app = _app || new Vue({
    router,
    render: h => h(VueApp)
  }).$mount('#appWrap')
})
store.checkToken()
