<template>
  <b-container class="page-test-pda-read-barcode">
    <h3 class="text-center mt-4 py-2 bg-light rounded ">Test scan code barre</h3>
    <div>
      <b-button variant="info" size="sm" class="py-0" :to="{ name: 'home' }">
        <i class="fas fa-angle-left" /><span class="smallx ml-2">Retour au menu</span>
      </b-button>
    </div>
    <div class="d-flex align-items-center flex-nowrap">
      <div>
        <div class="mt-3">Données reçues</div>
        <div class="text-muted smallx mb-3">(appel de la fonction <code>barcodeRead</code>)</div>
      </div>
      <div class="ml-auto">
        <b-button variant="danger" size="sm" class="py-0" :disabled="!barcodes.length" @click.prevent="resetData">Effacer</b-button>
      </div>
    </div>
    <div v-if="barcodes.length">
      <div :key="idxItem" v-for="(item, idxItem) in barcodes" class="dataitem">
        <div>
          <span>EAN : </span><strong>{{item.barcode}}</strong>
        </div>
        <div>
          <small>N° de série : </small><small>{{item.serial_number}}</small>
        </div>
        <div>
          <small>Adresse Mac : </small><small>{{item.mac_address}}</small>
        </div>
      </div>
    </div>
    <div v-else class="smallx text-muted text-center pt-4">Aucune donnée</div>
  </b-container>
</template>

<script>
import eventEmitter from '@/event-emitter'

export default {
  name: 'TestPdaReadBarcode',
  created () {
    eventEmitter.on('barcode:scanned', this.onBarcodeScanned)
  },
  beforeDestroy () {
    eventEmitter.off('barcode:scanned', this.onBarcodeScanned)
  },
  data () {
    return {
      barcodes: []
    }
  },
  methods: {
    onBarcodeScanned (data) {
      this.barcodes.push({
        barcode: data.barcode,
        serial_number: data.serial_number || '',
        mac_address: data.mac_address || ''
      })
    },
    resetData () {
      this.barcodes = []
    }
  }
}
</script>

<style scoped>
  .dataitem { border-top: 1px solid #dfd7ca; padding: 5px 0; line-height: 16px; }
  .dataitem:last-child { border-bottom: 1px solid #dfd7ca; }
  .dataitem > div > *:first-child { color: #8e8c84; }
  .dataitem > div:first-child { padding-bottom: 4px; }
</style>
