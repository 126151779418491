'use strict'

const _pick = (obj, keys) => {
  return keys.map(key => key in obj ? { [key]: obj[key] } : {}).reduce((res, o) => Object.assign(res, o), {})
}

const _indexBy = (arr, key) => {
  key = key || 'id'
  let _indexed = {}
  arr.forEach(o => { _indexed[o[key]] = o })
  return _indexed
}

const _sort = (arr, key) => {
  return arr.sort((a, b) => typeof a[key] === 'number' ? (a[key] < b[key] ? -1 : 1) : a[key].localeCompare(b[key]))
}

export default {
  pick: _pick,
  indexBy: _indexBy,
  sort: _sort
}
