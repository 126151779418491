<template>
  <div class="text-center pt-5">
    <h2 class="text-danger mb-4">{{title}}</h2>
    <div class="text-uppercase text-muted">{{message}}</div>
    <div class="mt-4">
      <router-link class="text-primary text-decoration-none" to="/">Retour à l'accueil</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  props: {
    title: String,
    message: String
  }
}
</script>
