<template>
  <b-overlay fixed :show="overlay.active" variant="light" opacity="0.6" blur="5px" rounded="sm" no-wrap>
    <template #overlay>
      <div class="b-overlay-content">
        <span class="b-overlay-icon">
          <strong v-if="overlay.countDown > 0">{{overlay.countDown}}</strong>
        </span>
        <div class="b-overlay-message">
          <div v-if="overlay.message">
            <div>{{overlay.message}}</div>
            <b-button variant="danger" size="sm" class="mt-2 py-0" @click="nextAction">Fermer</b-button>
          </div>
          <div v-else>Patientez svp...</div>
        </div>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import eventEmitter from '@/event-emitter'

export default {
  name: 'Overlay',
  props: {
    overlay: Object
  },
  created () {
    eventEmitter.on('action:started', this.actionStarted)
    eventEmitter.on('action:finished', this.actionFinished)
    eventEmitter.on('action:message', this.actionMessage)
  },
  beforeDestroy: function () {
    eventEmitter.off('action:started', this.actionStarted)
    eventEmitter.off('action:finished', this.actionFinished)
    eventEmitter.off('action:message', this.actionMessage)
  },
  methods: {
    actionStarted () {
      this.overlay.active = true
    },
    actionFinished (data) {
      this.overlay.next = data.next || null
      this.overlay.actiondata = data.actiondata || null
      this.nextAction()
    },
    actionMessage (data) {
      if (!this.overlay.active) {
        this.actionStarted()
      }
      this.overlay.message = data.message || null
      this.overlay.next = data.next || null
      this.overlay.countDown = data.countDown || 0
      this.actionCountDownTimer()
    },
    actionCountDownTimer () {
      if (this.overlay.countDown <= 0) return

      setTimeout(() => {
        this.overlay.countDown -= 1
        if (this.overlay.countDown === 0) this.nextAction()
        else this.actionCountDownTimer()
      }, 1000)
    },
    nextAction () {
      this.$emit('next-action')
    }
  }
}
</script>

<style scoped>
  .b-overlay { z-index: 100000 !important; }
  .b-overlay-content {
    text-align: center;
    width: 360px;
    max-width: 84%;
    margin: 0 auto;
  }
  .b-overlay-content .b-overlay-icon {
    font-weight: 400;
    font-size: 4rem;
    position: relative;
    background: transparent url("/images/wait.png") center center no-repeat;
    background-size: cover;
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .b-overlay-content .b-overlay-icon strong {
    font-size: 1.4rem;
    color: #e74c3c;
    position: absolute;
    top: auto;
    left: 8px;
    right: 2px;
    bottom: 6px;
  }
  .b-overlay-content .b-overlay-message {
    background-color: rgba(255,255,255,.8);
    border: 1px dashed #898989;
    padding: 8px;
    border-radius: 5px;
    margin: 4px 0 10px 0;
  }
</style>
