'use strict'

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store.js'

import VueLogin from './views/Login.vue'
import VueRegistration from './views/Registration.vue'
import VueNotFound from './views/NotFound.vue'
import VueNotAuthorized from './views/NotAuthorized.vue'
import VueHome from './views/Home.vue'
import VueTestPdaReadBarcode from './views/TestPdaReadBarcode'
import VueBluetoothSppInit from './views/BluetoothSppInit'

const VueStocks = () => import(/* webpackChunkName: "app-stocks" */ './views/Stocks.vue')
const VueTestsProducts = () => import(/* webpackChunkName: "app-tests" */ './views/TestsProducts.vue')
const VueTestsTrial = () => import(/* webpackChunkName: "app-trial" */ './views/TestsTrial.vue')
const VueTestsQcm = () => import(/* webpackChunkName: "app-trial" */ './views/TestsQcm.vue')

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/registration',
      component: VueRegistration,
      name: 'registration'
    },
    {
      path: '/login',
      component: VueLogin,
      name: 'login'
    },
    {
      path: '/',
      component: VueHome,
      name: 'home',
      beforeEnter: (to, from, next) => {
        if (store.userCan('test') || store.userCan('admin')) next()
        else next({ path: '/stocks/menu' })
      }
    },
    {
      path: '/stocks',
      name: 'stocks-home',
      redirect: { name: 'stocks', params: { action: 'menu' } }
    },
    {
      path: '/stocks/:action',
      name: 'stocks',
      component: VueStocks,
      meta: { permission: 'MeS' },
      beforeEnter: (to, from, next) => {
        if (store.getUserCurrentCenter()) next()
        else if (to.params.action !== 'menu') next({ path: '/stocks/menu' })
        else next()
      }
    },
    {
      path: '/tests/products',
      name: 'tests-products',
      component: VueTestsProducts,
      meta: { permission: 'admin' }
    },
    {
      path: '/tests/trial',
      name: 'tests-trial',
      component: VueTestsTrial,
      meta: { permission: 'test' }
    },
    {
      path: '/tests/qcm',
      name: 'tests-qcm',
      component: VueTestsQcm,
      meta: { permission: 'test' }
    },
    {
      path: '/test-pda-read-barcode',
      name: 'test-pda-read-barcode',
      component: VueTestPdaReadBarcode,
      meta: { public: true }
    },
    {
      path: '/bluetooth-spp-init',
      name: 'bluetooth-spp-init',
      component: VueBluetoothSppInit,
      meta: { public: true }
    },
    {
      path: '/not-authorized',
      name: '401',
      component: VueNotAuthorized
    },
    {
      path: '*',
      name: '404',
      component: VueNotFound
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.public) {
    next()
    return
  }

  const _isOfflineRoutes = ['registration', 'login'].indexOf(to.name) !== -1
  if (!store.isLogged() && !_isOfflineRoutes) {
    next({ path: '/login', query: { redirect: to.fullPath } })
  } else if (store.isLogged() && _isOfflineRoutes) {
    next({ path: '/' })
  } else {
    if (to.meta && to.meta.permission) {
      if (store.userCan(to.meta.permission)) {
        next()
      } else {
        next({ path: '/not-authorized' })
      }
    } else {
      next()
    }
  }
})

export default router
